<template>
  <div>
    <v-row class="match-height">
      <v-col
        cols="12"
        md="4"
      >
        <v-card>
          <v-card-text class="greeting-title">
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <div>
                  <p class="text-no-wrap text-xl">
                    Selamat Datang di
                  </p>
                  <p class="text--primary font-weight-bold text-xl mb-0">
                    Dashboard Superadmin
                    <span>🎉</span>
                  </p>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <div>
                  <!-- <v-img
                    width="80"
                    src="@/assets/images/misc/tree-4.png"
                    class="gamification-tree-4"
                  ></v-img> -->
                  <v-img
                    width="110"
                    src="@/assets/images/3d-characters/pose-2.png"
                    class="gamification-john-pose-2"
                  ></v-img>
                  <!-- <v-img
                    width="80"
                    src="@/assets/images/misc/tree.png"
                    class="gamification-tree"
                  ></v-img> -->
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- <v-card-title class="greeting-title d-flex flex-nowrap text-2xl">
          </v-card-title> -->
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <h4>Library</h4>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col
                v-for="(library,index) in libraries"
                :key="index"
                md="4"
              >
                <v-row>
                  <v-col md="5">
                    <v-card :color="library.color">
                      <v-container>
                        <v-img
                          :src="library.icon"
                        ></v-img>
                      </v-container>
                    </v-card>
                  </v-col>
                  <v-col md="7">
                    <span>{{ library.title }}</span>
                    <p class="font-weight-bold text--black mb-0">
                      {{ library.total }}
                      <span
                        v-if="library.ratio > 0"
                        class="font-weight-light text-sm success--text mb-0 "
                      >
                        {{ "+"+library.ratio }}
                      </span>

                      <span
                        v-else
                        class="font-weight-light text-sm error--text mb-0 "
                      >
                        {{ library.ratio }}
                      </span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row>
    </v-row> -->
  </div>
</template>

<script>
import iconBag from '@/assets/icons/white/bag.svg'
import iconBook from '@/assets/icons/white/book.svg'
import iconChart from '@/assets/icons/white/chart.svg'
import iconSchool from '@/assets/icons/white/courthouse.svg'
import iconDocumentText from '@/assets/icons/white/document-text.svg'
import iconKeyboard from '@/assets/icons/white/keyboard.svg'
import iconMessageText from '@/assets/icons/white/message-text.svg'
import iconMessages from '@/assets/icons/white/messages-2.svg'
import iconMonitorMobile from '@/assets/icons/white/monitor-mobile.svg'
import iconMonitor from '@/assets/icons/white/monitor.svg'
import iconMusic from '@/assets/icons/white/music.svg'
import iconNote from '@/assets/icons/white/note-2.svg'
import iconPlay from '@/assets/icons/white/play.svg'
import iconReceipt from '@/assets/icons/white/receipt-2.svg'
import iconTaskSquare from '@/assets/icons/white/task-square.svg'
import iconUserOctagon from '@/assets/icons/white/user-octagon.svg'
import iconUser from '@/assets/icons/white/user.svg'
import dateTimeFormat from '@/utils/date/dateTimeFormat'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'
import { mdiChevronDown, mdiDotsVertical } from '@mdi/js'
// import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'DashboardSuperadmin',
  components: {
    // VueApexCharts,
  },
  data() {
    return {
      vuetify: getVuetify(),
      icons: {
        mdiDotsVertical,
        mdiChevronDown,
        iconBook,
        iconPlay,
        iconMusic,
        iconSchool,
        iconUser,
        iconChart,
        iconBag,
        iconUserOctagon,
        iconMonitor,
        iconMonitorMobile,
        iconNote,
        iconReceipt,
        iconMessageText,
        iconMessages,
        iconKeyboard,
        iconTaskSquare,
        iconDocumentText,
      },
      dashboard: {},
      isLoadingCard: true,
      favoriteBooks: [],
      bookPurchases: [],
      yearActivities: [],
      chartOptions: {
        colors: ['#EC008C'],
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '35%',
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter: value => kFormatter(value, 0),
          },
        },
        grid: {
          strokeDashArray: 10,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          curve: 'smooth',
          width: 6,
          lineCap: 'round',
          colors: ['#fff'],
        },
        responsive: [
          {
            breakpoint: 1400,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '50%',
                },
              },
            },
          },
        ],
      },
      chartData: [
        {
          data: [
            {
              x: '',
              y: 0,
            },
          ],
        },
      ],
      customChartColor: '#3b3559',
      libraries: [
        {
          icon: iconBook,
          title: 'E-Book',
          total: 0,
          color: '#EC008C',
          keyName: 'count_ebook',
          keyRatioName: 'ratio_ebook',
        },
        {
          icon: iconMusic,
          title: 'Audio Book',
          total: 0,
          color: '#7D2B8B',
          keyName: 'count_audio',
          keyRatioName: 'ratio_audio',
        },
        {
          icon: iconPlay,
          title: 'Video Book',
          total: 0,
          color: '#149FE5',
          keyName: 'count_video',
          keyRatioName: 'ratio_video',
        },
      ],
      schools: [],
      activeSchools: [],
      activeStudents: [],
      activeTeachers: [],
      service: 'dashboardadmin',
      date: [
        {
          id: `01-${new Date().getFullYear()}`,
          name: `January ${new Date().getFullYear()}`,
        },
        {
          id: `02-${new Date().getFullYear()}`,
          name: `February ${new Date().getFullYear()}`,
        },
        {
          id: `03-${new Date().getFullYear()}`,
          name: `March ${new Date().getFullYear()}`,
        },
        {
          id: `04-${new Date().getFullYear()}`,
          name: `April ${new Date().getFullYear()}`,
        },
        {
          id: `05-${new Date().getFullYear()}`,
          name: `May ${new Date().getFullYear()}`,
        },
        {
          id: `06-${new Date().getFullYear()}`,
          name: `June ${new Date().getFullYear()}`,
        },
        {
          id: `07-${new Date().getFullYear()}`,
          name: `July ${new Date().getFullYear()}`,
        },
        {
          id: `08-${new Date().getFullYear()}`,
          name: `August ${new Date().getFullYear()}`,
        },
        {
          id: `09-${new Date().getFullYear()}`,
          name: `September ${new Date().getFullYear()}`,
        },
        {
          id: `10-${new Date().getFullYear()}`,
          name: `October ${new Date().getFullYear()}`,
        },
        {
          id: `11-${new Date().getFullYear()}`,
          name: `November ${new Date().getFullYear()}`,
        },
        {
          id: `12-${new Date().getFullYear()}`,
          name: `December ${new Date().getFullYear()}`,
        },
      ],
      paramsDate: '',
      dateNow: '',
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      total: 0,
      userActivities: [],
    }
  },
  mounted() {
    this.dateNow = `${this.monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`
    this.checkFirstParams()
    this.getDataCount({ date: this.paramsDate })
  },
  methods: {
    async getDataCount(params) {
      await this.$services.ApiServices.list(this.service, { ...params }).then(({ data }) => {
        this.dashboard = data.data
        data.data.user_activities.forEach(el => this.yearActivities.push(el.year))
        const chartData = [
          {
            data: [],
          },
        ]
        Object.keys(data.data).forEach((key, item) => {
          this.libraries.forEach(el => {
            if (el.keyName === key) {
              el.total = data.data[key]
            }
            if (el.keyRatioName === key) {
              el.ratio = data.data[key]
            }
          })
        })
      })
      this.isLoadingCard = false
    },
    checkFirstParams() {
      this.date.forEach(item => {
        if (item.name === this.dateNow) this.paramsDate = item.id
      })
    },
    dateFormat(time) {
      return dateTimeFormat(time, '/')
    },
    randomBgColor() {
      const color = ['warning', 'primary', 'success', 'error']

      const random = Math.floor(Math.random() * color.length)

      return color[random]
    },
    randomTextColor() {
      const colorText = ['warning--text', 'primary--text', 'success--text', 'error--text']

      const random = Math.floor(Math.random() * colorText.length)

      return colorText[random]
    },
  },
  // beforeDestroy() {
  //   if (this.chart) {
  //     this.chart.dispose()
  //   }
  // },
}
</script>

<style lang="scss" scoped>
.comboboxHidden::v-deep > .v-input__control > .v-text-field__details {
  display: none !important;
}
.card-info,
.card-img {
  z-index: 1;
}
.card-info .font-weight,
.card-info .font-light {
  color: #fff;
}
.chartdiv {
  width: 100%;
  height: 350px;
}
.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}
</style>
